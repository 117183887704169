import Vue from 'vue';
import VueRouter from 'vue-router';
import Donations from '../views/Donations.vue';
// import Home from '../views/Home.vue'
import Coupon from '../views/Coupon.vue';
import Homologation from '../views/Homologation.vue';
import Login from '../views/Login.vue';
import Memberships from '../views/Memberships.vue';
import Payments from '../views/Payments.vue';
import Pets from '../views/Pets.vue';
import PlanDetails from '../views/PlanDetails.vue';
import Plans from '../views/Plans.vue';
import Profile from '../views/Profile.vue';
import Redemptions from '../views/Redemptions.vue';
import Users from '../views/Users.vue';
import Refunds from '../views/Refunds.vue';
import Companies from '../views/Companies.vue';
import ServicesRecords from '../views/ServicesRecords.vue';
import RegisterRefund from '../views/RegisterRefund.vue';
import CreateUser from '../views/CreateUser.vue';
import MRR from '../views/Mrr.vue';
import MRRResume from '../views/MrrResume.vue';
import AddPet from '../views/AddPet.vue';

//import Dashboard from '../views/Dashboard.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        // /search/screens -> /search?q=screens
        path: '/',
        redirect: () => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return { path: '/memberships' };
        }
      },
      /*{
        path: '/home',
        name: 'Dashboard',
        component: Dashboard
      },*/
      {
        path: '/users',
        name: 'Users',
        component: Users
      },
      {
        path: '/companies',
        name: 'Companies',
        component: Companies
      },
      {
        path: '/plans',
        name: 'Plans',
        component: Plans
      },
      {
        path: '/plans/:planId',
        name: 'PlanDetails',
        component: PlanDetails,
        props: true
      },
      {
        path: '/memberships',
        name: 'Memberships',
        component: Memberships
      },
      {
        path: '/memberships/:userId',
        name: 'Memberships by User',
        component: Memberships
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: '/redemptions',
        name: 'Redemptions',
        component: Redemptions
      },
      {
        path: '/donations',
        name: 'Donations',
        component: Donations
      },
      {
        path: '/payments',
        name: 'Payments',
        component: Payments
      },
      {
        path: '/pets',
        name: 'Pets',
        component: Pets
      },
      {
        path: '/pets/:userId',
        name: 'Pets by User',
        component: Pets
      },
      {
        path: '/memberships/:userId/add',
        name: 'AddPet',
        component: AddPet,
        props: true
      },
      {
        path: '/homologations',
        name: 'Homologations',
        component: Homologation
      },
      {
        path: '/coupons',
        name: 'Coupons',
        component: Coupon
      },
      {
        path: '/refunds',
        name: 'Refunds',
        component: Refunds
      },
      {
        path: '/registers/register-refunds',
        name: 'RegisterRefund',
        component: RegisterRefund
      },
      {
        path: '/registers/servicesrecords',
        name: 'Registro Servicios',
        component: ServicesRecords
      },
      {
        path: '/create-user',
        name: 'Crear Usuario',
        component: CreateUser
      },
      {
        path: '/mrr/users',
        name: 'MRR',
        component: MRR
      },
      {
        path: '/mrr/values',
        name: 'MRR Resumen',
        component: MRRResume
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
